var exports = {};

exports = function repeat(ele, num) {
  if (Array.prototype.fill) {
    return new Array(num).fill(ele);
  }

  var arr = new Array(num);

  for (var i = 0; i < num; i++) {
    arr[i] = ele;
  }

  return arr;
};

export default exports;